<template lang="pug">
  div(style="max-width: 720px; margin: auto")
    h1 {{$t('musicSummary.title')}}
    .py-2

    template(v-if="!$profile.$")
      Divider
      v-list.py-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title {{$t('users.notLinked')}}
            v-list-item-subtitle {{$t('users.guide')}}

      Divider
      .py-2

    template(v-else)
      template(v-if="$vuetify.breakpoint.smAndDown")
        Divider
        v-list.py-0(dense)
          Chart(:difficulty="difficulty0", :type="type", label, height=240)
          .d-flex.px-2
            v-select.px-2.pb-2(dense, hide-details, v-model="type", :items="['bar', 'line'].map(x => ({text: $t(`musicSummary.chartTypes.${x}`), value: x}))")
            v-select.px-2.pb-2(dense, hide-details, v-model="difficulty0", :items="['easy', 'normal', 'hard', 'expert', 'master', {text: $t('musicSummary.all'), value: 'all'}]")

        Divider
        .py-2

        Divider
        Table

        Divider
        .py-2

      template(v-else)
        Divider
        v-list.py-0(dense)
          Chart(difficulty="all", :type="type", label)
          v-select.px-4.pb-2(dense, hide-details, v-model="type", :items="['bar', 'line'].map(x => ({text: $t(`musicSummary.chartTypes.${x}`), value: x}))")

        Divider
        .py-2

        Divider
        .d-flex
          .split(style="width: calc((100% - 1px) * 1/2); height: inherit")
            Table

          v-divider(vertical)

          .split(style="width: calc((100% - 1px) * 1/2); height: inherit")
            v-list.py-0(dense)
              Chart(:difficulty="difficulty", type="bar", label, height=166)       
              v-select.px-4.pb-2(dense, hide-details, v-model="difficulty", :items="['easy', 'normal', 'hard', 'expert', 'master']")

        Divider
        .py-2

      h3 {{$t('musicSummary.rating')}}

      Divider
      v-list.py-0(dense)
        v-list-item
          v-list-item-content
            v-list-item-title {{$t('musicSummary.rating')}}
          v-list-item-action
            v-list-item-action-text {{$profile.$.userRating.rating}}

      Divider
      p.hint {{$t('musicSummary.hint1')}}

      .py-2

      h3 {{$t('musicSummary.best39')}}

      Divider
      v-list.py-0(dense)
        template(v-for="_, i in Math.ceil($profile.$.userRating.userMusicDifficultyStatuses.length / rowSize)")
          Divider(v-if="i")
          .d-flex
            template(v-for="_, j in rowSize")
              v-divider(vertical, v-if="j")

              div(:style="{width: `calc((100% - ${rowSize-1}px) / ${rowSize})`}")
                template(v-if="i*rowSize+j < $profile.$.userRating.userMusicDifficultyStatuses.length")
                  MusicDifficultyStatus(:i="i*rowSize+j")
    
      Divider
      p.hint {{$t('musicSummary.hint2')}}

      .py-2

      h3 {{$t('musicSummary.recommendings')}}
      
      Divider
      v-list.py-0(dense)
        template(v-for="_, i in Math.min(2, Math.ceil($profile.$.userRating.recommendings.length / rowSize))")
          Divider(v-if="i")
          .d-flex
            template(v-for="_, j in rowSize")
              v-divider(vertical, v-if="j")

              div(:style="{width: `calc((100% - ${rowSize-1}px) / ${rowSize})`}")
                template(v-if="i*rowSize+j < $profile.$.userRating.recommendings.length")
                  Recommending(:i="i*rowSize+j")

      Divider
      .py-2

        //- template(v-for="userMusicDifficultyStatus in $profile.$.userRating.userMusicDifficultyStatuses")
        //-   MusicDifficultyStatus(:userMusicDifficultyStatus="userMusicDifficultyStatus")
</template>

<script>
import MusicDifficultyStatus from './MusicDifficultyStatus';
import Chart from './Chart';
import Table from './Table';
import Recommending from './Recommending';

export default {
  name: 'MusicSummary',

  data() {
    return {
      type: 'line',
      difficulty0: 'all',
      difficulty: 'master',
    };
  },

  components: { MusicDifficultyStatus, Chart, Table, Recommending },

  computed: {
    rowSize() {
      if (this.$vuetify.breakpoint.xs) {
        return 3;
      } else if (this.$vuetify.breakpoint.sm) {
        return 4;
      } else {
        return 5;
      }
    },
  },

};
</script>