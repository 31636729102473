<template lang="pug">
  v-list-item(:to="{ path: `/music/${id}`, query: { [`musicDifficulties.${difficulty}.playLevel`]: musicDifficulty.playLevel, 'sort.function': `musicDifficulty.playLevel+(musicDifficulty.${userMusicDifficultyStatus.rank == 'P' ? 'fullPerfectAdjust' : userMusicDifficultyStatus.rank == 'F' ? 'fullComboAdjust' : 'playLevelAdjust'}||0)`, 'sort.difficulty': difficulty, 'sort.order': -1 }}")
    .d-block(style="width: 100%")
      .d-flex
        v-list-item-avatar(tile)
          v-img(:src="`${$sekai.assetHost}/thumbnail/music_jacket/${music.assetbundleName}.webp`")

        v-list-item-content(style="overflow: visible")
          v-list-item-title # {{i+1}}
          v-list-item-subtitle.mr-n2(style="overflow: visible; margin-left: min(0px, 50% - 24px)")
            MusicDifficulty(:id="id", :difficulty="difficulty", style="width: calc(60% + 19.6px)")

      v-list-item-content.pt-0
        v-list-item-title {{music.title}}

        v-list-item-action-text
          template(v-if="!musicDifficulty.playLevelAdjust")
            | {{musicDifficulty.playLevel}} ?
          template(v-else-if="userMusicDifficultyStatus.rank == 'P'")
            | {{(musicDifficulty.playLevel + musicDifficulty.fullPerfectAdjust).toFixed(1)}}
          template(v-else-if="userMusicDifficultyStatus.rank == 'F'")
            | {{(musicDifficulty.playLevel + musicDifficulty.fullComboAdjust).toFixed(1)}}
          template(v-else-if="userMusicDifficultyStatus.rank == 'C'")
            | {{(musicDifficulty.playLevel + musicDifficulty.playLevelAdjust).toFixed(1)}}

          |  → 

          | {{userMusicDifficultyStatus.rating.toFixed(0)}}
</template>

<script>
import MusicDifficulty from '@/components/MusicDifficulty';

export default {
  name: 'MusicDifficultyStatus',

  props: ['i'],

  components: { MusicDifficulty },

  computed: {
    userMusicDifficultyStatus() {
      return this.$profile.$.userRating.userMusicDifficultyStatuses[this.i];
    },
    id() {
      return this.userMusicDifficultyStatus.musicId;
    },
    difficulty() {
      return this.userMusicDifficultyStatus.musicDifficulty;
    },
    music() {
      return this.$db.musics[this.id];
    },
    musicDifficulty() {
      return this.$db.musicDifficulties[this.id][this.difficulty];
    }
  },
};
</script>

<style>
</style>