<template lang="pug">
  div(style="overflow-x: hidden")
    div(ref="chart", style="width: 100%", :style="{height: `${height}px`}")
    //- p {{levels}}
    //- p {{ranks}}
</template>

<script>
import * as echarts from 'echarts';

export default {
  name: 'Chart',

  props: {
    difficulty: { default: 'all' },
    type: { default: 'line' },
    label: { type: Boolean, default: false },
    height: { default: 270 },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {
    sortedMusics() {
      return Object.values(this.$db.musics).filter(music => music.publishedAt <= new Date().valueOf());
    },
    levels() {
      let rank = this.ranks.all;
      let levels = rank.data.map((x, i) => x ? i : 0).filter(x => x);
      let minLevel = Math.min(...levels);
      let maxLevel = Math.max(...levels);
      levels = [];
      for (let i = minLevel; i <= maxLevel; i++) {
        levels.push(i);
      }
      return levels;
    },
    ranks() {
      let ranks = {
        all: {
          id: 'all',
          name: this.$t('musicSummary.all'),
          color: this.$vuetify.theme.dark ? '#222222' : '#eeeeee',
          areaColor: this.$vuetify.theme.dark ? '#222222' : '#eeeeee',
          label: true,
          labelColor: this.$vuetify.theme.dark ? '#777777' : '#777777',
          textBorderColor: this.$vuetify.theme.dark ? '#333333' : '#ffffff',
          textBorderWidth: 2,
          data: new Array(50).fill(0),
        },
        available: {
          id: 'available',
          name: this.$t('musicSummary.available'),
          color: this.$vuetify.theme.dark ? '#2d2d2d' : '#e6e6e6',
          areaColor: this.$vuetify.theme.dark ? '#2d2d2d' : '#e6e6e6',
          label: false,
          labelColor: this.$vuetify.theme.dark ? '#777777' : '#777777',
          textBorderColor: this.$vuetify.theme.dark ? '#333333' : '#ffffff',
          textBorderWidth: 2,
          data: new Array(50).fill(0),
        },
        C: {
          id: 'C',
          color: '#ffb74d',
          areaColor: '#ffb74d',
          label: true,
          labelColor: '#ffb74d',
          textBorderColor: this.$vuetify.theme.dark ? '#333333' : '#ffffff',
          textBorderWidth: 2,
          data: new Array(50).fill(0),
        },
        F: {
          id: 'F',
          color: '#f06292',
          areaColor: '#f06292',
          label: true,
          labelColor: '#f06292',
          textBorderColor: this.$vuetify.theme.dark ? '#333333' : '#ffffff',
          textBorderWidth: 2,
          data: new Array(50).fill(0),
        },
        P: {
          id: 'P',
          color: '#ffffff',
          areaColor: '#ffffff',
          label: true,
          labelColor: '#64b5f6',
          textBorderColor: this.$vuetify.theme.dark ? '#ffffff' : '#ffffff',
          textBorderWidth: 2,
          data: new Array(50).fill(0),
        },
      };
      for (let music of this.sortedMusics) {
        for (let difficulty of this.difficulty != 'all' ? [this.difficulty] : ['easy', 'normal', 'hard', 'expert', 'master']) {
          let level = this.$db.musicDifficulties[music.id][difficulty].playLevel;
          let status = this.$profile.$.userMusics[music.id].userMusicDifficultyStatuses[difficulty];
          ranks.all.data[level] += 1;
          if (status.musicDifficultyStatus == 'available') ranks.available.data[level] += 1;
          if (status.rank >= 'C') ranks.C.data[level] += 1;
          if (status.rank >= 'F') ranks.F.data[level] += 1;
          if (status.rank >= 'P') ranks.P.data[level] += 1;
        }
      }
      return ranks;
    },
    size() {
      return [this.$refs.chart?.clientWidth, this.$refs.chart?.clientHeight];
    },
    options() {
      return {
        grid: { top: this.label && this.type == 'bar' ? 48 : 32, bottom: 32, left: 16, right: 16 },
        xAxis: {
          type: 'category',
          data: this.levels,
          axisTick: { alignWithLabel: true },
        },
        yAxis: {
          type: 'value',
          axisLabel: false,
          splitLine: false,
        },
        legend: {
          show: true,
          selectedMode: false,
          icon: 'roundRect',
          textStyle: {
            color: this.$vuetify.theme.dark ? '#777777' : '#777777',
          },
          top: 8,
          data: Object.values(this.ranks).map(rank => rank.id),
          itemWidth: 12,
          itemHeight: 12,
          formatter: rankId => {
            return this.ranks[rankId].name || this.ranks[rankId].id || '';
          }
        },
        series: Object.values(this.ranks).map(rank => ({
          name: rank.id,
          type: this.type,
          barGap: '-100%',
          symbol: 'none',
          connectNulls: true,
          color: rank.color,
          areaStyle: { color: rank.areaColor },
          data: this.levels.map(i => rank.data[i] / this.ranks.all.data[i]),
          label: {
            show: this.label && rank.label,
            // distance: 3,
            position: 'top',
            color: rank.labelColor,
            textBorderColor: rank.textBorderColor,
            textBorderWidth: rank.textBorderWidth,
            fontWeight: 900,
            formatter: params => {
              return this.ranks[params.seriesName].data[params.name] || '';
            }
          },
        })),
      };
    }
  },

  methods: {
    draw() {
      if (!this.chart) {
        this.chart = echarts.init(this.$refs.chart);
      }
      this.chart.setOption(this.options);
      this.chart.resize();
      window.addEventListener('resize', () => this.chart.resize());
    },
  },

  mounted() {
    this.$nextTick(function () {
      this.draw();
    });
  },

  watch: {
    options() {
      this.$nextTick(function () {
        this.draw();
      });
    },
    size() {
      this.$nextTick(function () {
        this.draw();
      });
    }
  }
};
</script>