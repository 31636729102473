<template lang="pug">
  v-list.py-0(dense)
    v-list-item
      div.mr-4.my-auto(style="width: 40px")

      v-list-item-content
        v-list-item-subtitle.d-flex
          template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
            .pr-2(v-if="i")
            div(style="width: calc(20% - 3.2px)")
              MusicDifficulty(:difficulty="difficulty")

    template(v-for="rank in ['P', 'F', 'C']")
      Divider(inset=72)
      v-list-item
        div.mr-4.my-auto(style="width: 40px")
          v-list-item-subtitle
            MusicDifficulty(:rank="rank")
        
        v-list-item-content
          .d-flex
            template(v-for="difficulty, i in ['easy', 'normal', 'hard', 'expert', 'master']")
              .pr-2(v-if="i")
              v-list-item-action-text.text-center(style="width: calc(20% - 3.2px)")
                router-link(:to="{ path: '/music', query: {[`vue.$profile.$.userMusics[music.id].userMusicDifficultyStatuses.${difficulty}.rank`]: rank, 'sort.function': `musicDifficulty.playLevel+(musicDifficulty.${rank == 'P' ? 'fullPerfectAdjust' : rank == 'F' ? 'fullComboAdjust' : 'playLevelAdjust'}||0)`, 'sort.difficulty': difficulty, 'sort.order': -1 }}")
                  | {{Object.values($profile.$.userMusics).map(userMusic => userMusic.userMusicDifficultyStatuses[difficulty].rank >= rank).reduce((s, a) => s + a, 0)}}

    Divider(inset=72)

    v-list-item
      div.mr-4.my-auto(style="width: 40px")
        v-list-item-subtitle.text-center
          | {{$t('musicSummary.all')}}

      v-list-item-content
        .text-center
          v-list-item-action-text {{Object.values($db.musics).filter(music => music.publishedAt <= new Date().valueOf()).length}}

</template>

<script>
import MusicDifficulty from '@/components/MusicDifficulty';

export default {
  name: 'Table',

  components: { MusicDifficulty },
};
</script>
